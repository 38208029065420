import React from 'react';
import { Button } from 'primereact/button';
import { usePage } from '@inertiajs/react';

interface ThemeButtonProps {
  type: 'button' | 'submit';
  children: any;
  themeButtonStyleProps?: any;
}

export default function ThemeButton(props: ThemeButtonProps) {
  const { type, children, themeButtonStyleProps } = props;
  const { banner_color: bannerColor } = (usePage().props.auth as Auth).company?.settings || {};

  const themeButtonStyle = bannerColor ? { background: bannerColor } : themeButtonStyleProps;

  return (
    <Button type={type} style={themeButtonStyle}>
      {children}
    </Button>
  );
}
